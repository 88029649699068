import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetDebtCreditBalancesMutation, useGetDebtCreditMutation } from "src/app/services/finance";
import { IDebtCredit, IDebtCreditObj } from "src/app/services/finance/type";
import { useTypedSelector } from "src/app/store";
import { findMonth } from "src/utils";
import { IDebtCreditTable, defCreditTableObj } from "./components/columns";

export default function useDebitCredit() {
  const [get, { isLoading }] = useGetDebtCreditMutation();
  const [getBalances] = useGetDebtCreditBalancesMutation();
  const [data, setData] = useState<IDebtCreditTable>(); 
  const [searchParams, setSearchParams] = useSearchParams();
  const year = searchParams.get("date");

  const handleGet = () => {
    if (!year) {
      const date = new Date();
      setSearchParams({ date: date.getFullYear().toString() });
      return;
    }
    getBalances(`?from_date=${Number(year)}-01-15`)
      .unwrap()
      .then((balance) => {
        get(`?from_date=${Number(year) - 1}-12-15&to_date=${year}-12-15`)
          .unwrap()
          .then((response) => {
            let res = response as IDebtCredit;
            setData({
              customer: makeData(res.customer, balance.customer),
              employee: makeData(res.employee, balance.employee),
              rent: makeData(res.rent, balance.rent),
              supplier: makeData(res.supplier, balance.supplier),
            });
          });
      });
  };

  useEffect(() => {
    handleGet();
  }, [searchParams]);

  const makeData = (arr: IDebtCreditObj[], balance: IDebtCreditObj[]) => {
    console.log("balance", balance);
    let list: any[] = [];
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;
    const isCurrentYear = Number(year) === currentYear;
    const hasntComeYear = Number(year) > currentYear;

    arr.forEach((item) => {
      const month = findMonth(item.date.slice(5, 7));
      const current = list.find((el) => el.contingent?.id === item?.contingent.id);

      if (current) {
        current[month + "_gap"] = item.remaining;
        current[month + "_debt"] = item.debt;
        current[month + "_credit"] = item.credit;
      } else {
        let obj: any = {
          contingent: item.contingent,
          date: item.date,
          ...defCreditTableObj,
        };
        obj[month + "_gap"] = item.remaining;
        obj[month + "_debt"] = item.debt;
        obj[month + "_credit"] = item.credit;

        list.push(obj);
      }
    });

    balance.forEach((last) => {
      const current = list.find((el) => el.contingent?.id === last?.contingent?.id);
      const last_gap = last.remaining + last.debt - last.credit;
      if (current) {
        if (current.jan_gap === 0) current.jan_gap = last_gap;
      } else {
        let new_obj = {
          contingent: last.contingent,
          date: last.date,
          ...defCreditTableObj,
        };
        new_obj.jan_gap = last_gap;
        list.push(new_obj);
      }
    });

    if (!hasntComeYear) {
      list.forEach((item) => {
        if (item.jan_gap === 0) {
          let last_obj = balance.find((last) => last.contingent === item?.contingent?.id);
          if (last_obj) item.jan_gap = last_obj.remaining;
        }

        if (isCurrentYear) {
          if (item.feb_gap === 0 && currentMonth >= 1)
            item.feb_gap = (item.jan_gap || 0) + (item.jan_debt || 0) - (item.jan_credit || 0);
          if (item.mar_gap === 0 && currentMonth >= 2)
            item.mar_gap = (item.feb_gap || 0) + (item.feb_debt || 0) - (item.feb_credit || 0);
          if (item.apr_gap === 0 && currentMonth >= 3)
            item.apr_gap = (item.mar_gap || 0) + (item.mar_debt || 0) - (item.mar_credit || 0);
          if (item.may_gap === 0 && currentMonth >= 4)
            item.may_gap = (item.apr_gap || 0) + (item.apr_debt || 0) - (item.apr_credit || 0);
          if (item.june_gap === 0 && currentMonth >= 5)
            item.june_gap = (item.may_gap || 0) + (item.may_debt || 0) - (item.may_credit || 0);
          if (item.july_gap === 0 && currentMonth >= 6)
            item.july_gap = (item.june_gap || 0) + (item.june_debt || 0) - (item.june_credit || 0);
          if (item.aug_gap === 0 && currentMonth >= 7)
            item.aug_gap = (item.july_gap || 0) + (item.july_debt || 0) - (item.july_credit || 0);
          if (item.sep_gap === 0 && currentMonth >= 8)
            item.sep_gap = (item.aug_gap || 0) + (item.aug_debt || 0) - (item.aug_credit || 0);
          if (item.oct_gap === 0 && currentMonth >= 9)
            item.oct_gap = (item.sep_gap || 0) + (item.sep_debt || 0) - (item.sep_credit || 0);
          if (item.nov_gap === 0 && currentMonth >= 10)
            item.nov_gap = (item.oct_gap || 0) + (item.oct_debt || 0) - (item.oct_credit || 0);
          if (item.dec_gap === 0 && currentMonth >= 11)
            item.dec_gap = (item.nov_gap || 0) + (item.nov_debt || 0) - (item.nov_credit || 0);
        } else {
          if (item.feb_gap === 0) item.feb_gap = (item.jan_gap || 0) + (item.jan_debt || 0) - (item.jan_credit || 0);
          if (item.mar_gap === 0) item.mar_gap = (item.feb_gap || 0) + (item.feb_debt || 0) - (item.feb_credit || 0);
          if (item.apr_gap === 0) item.apr_gap = (item.mar_gap || 0) + (item.mar_debt || 0) - (item.mar_credit || 0);
          if (item.may_gap === 0) item.may_gap = (item.apr_gap || 0) + (item.apr_debt || 0) - (item.apr_credit || 0);
          if (item.june_gap === 0) item.june_gap = (item.may_gap || 0) + (item.may_debt || 0) - (item.may_credit || 0);
          if (item.july_gap === 0)
            item.july_gap = (item.june_gap || 0) + (item.june_debt || 0) - (item.june_credit || 0);
          if (item.aug_gap === 0) item.aug_gap = (item.july_gap || 0) + (item.july_debt || 0) - (item.july_credit || 0);
          if (item.sep_gap === 0) item.sep_gap = (item.aug_gap || 0) + (item.aug_debt || 0) - (item.aug_credit || 0);
          if (item.oct_gap === 0) item.oct_gap = (item.sep_gap || 0) + (item.sep_debt || 0) - (item.sep_credit || 0);
          if (item.nov_gap === 0) item.nov_gap = (item.oct_gap || 0) + (item.oct_debt || 0) - (item.oct_credit || 0);
          if (item.dec_gap === 0) item.dec_gap = (item.nov_gap || 0) + (item.nov_debt || 0) - (item.nov_credit || 0);
        }
      });
    }

    console.log("list", list);
    return list;
  };

  return {
    isLoading,
    data,
  };
}
