import ReactEcharts from "echarts-for-react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetSourcesOutcomeStatMutation } from "src/app/services/finance";
import { useTypedSelector } from "src/app/store";
import FilterDate from "src/components/filter/FilterDate";
import FilterSource from "src/components/filter/FilterSource";
import { handleMakeStatUrl } from "src/utils";
import ChartHeader from "../../components/chartHeader";
import { monthes } from "../../constants";
import "./revenue.scss";
import { useTranslation } from "react-i18next";

function RevenuePage() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);
  //Get section
  const [searchParams] = useSearchParams();
  const [getSourcesOutcomeStat] = useGetSourcesOutcomeStatMutation();
  const { sorcesOutcomeStat } = useTypedSelector((state) => state.stat);

  const handleGet = () => {
    let url = handleMakeStatUrl(searchParams, [
      "source_id",
      "date_year",
      "company_id",
    ]);
    getSourcesOutcomeStat(url);
  };
  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchParams.get("date_year"),
    searchParams.get("source_id"),
    searchParams.get("company_id"),
  ]);

  const option = {
    tooltip: {
      trigger: "item",
      formatter: function (params: any) {
        return `${params.seriesName}<br />
                ${params.name}: ${params.data?.prettier_value} (${params.data?.percent}%)`;
      },
    },
    legend: {
      textStyle: {
        color: colors.color_black,
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "16%",
      containLabel: true,
      height: 400,
    },
    xAxis: {
      type: "category",
      data: monthes,
    },
    yAxis: {
      type: "value",
    },
    series: sorcesOutcomeStat?.series,
  };

  return (
    <div className="balance" id="revenue">
      <div className="balance-content">
        <ChartHeader
          name={t("subControlPanel.revenue")}
          filters={
            <div className="chart-header-filters">
              <FilterSource />
              <FilterDate format="year" name="date_year" />
            </div>
          }
        />

        <div className="statistics-chart balance-chart">
          <div className="balance-chart-content" style={{ paddingTop: 24 }}>
            <ReactEcharts
              opts={{ renderer: "svg", height: 586 }}
              option={option}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RevenuePage;
