import type { DatePickerProps } from "antd";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { formattedToday, formattedYesterday } from "src/static/constants";
import "./filter.scss";
import { useTranslation } from "react-i18next";

export const dateFormat = "YYYY-MM-DD";
export const monthFormat = "YYYY-MM";
export const yearFormat = "YYYY";

interface Props {
  format?: "date" | "month" | "year";
  name?: "date" | "date_month" | "date_year" | "year";
  large?: boolean;
  page?: string;
}

function FilterDate({ format = "date", name = "date", large, page }: Props) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const onChange: DatePickerProps["onChange"] = (_, dateString) => {
    if (page === "dashboard") {
      if (dateString === formattedToday) handleMakeParams("type", "today");
      else if (dateString === formattedYesterday)
        handleMakeParams("type", "yesterday");
      else handleMakeParams("type", "day");
    }
    handleMakeParams(name, dateString);
  };

  const handleMakeParams = (key: string, value: string) => {
    if (value) {
      if (searchParams.has(key)) searchParams.set(key, value);
      else searchParams.append(key, value);
    } else searchParams.delete(key);
    setSearchParams(searchParams);
  };

  const defDate = searchParams.get(name);
  const defFormat =
    format === "date"
      ? dateFormat
      : format === "month"
      ? monthFormat
      : yearFormat;

  return (
    <div className="filter-date">
      <DatePicker
        format={defFormat}
        picker={
          format === "date" ? "date" : format === "month" ? "month" : "year"
        }
        onChange={onChange}
        // bordered={false}
        value={defDate ? dayjs(defDate, defFormat) : undefined}
        size={large ? "large" : "middle"}
        placeholder={
          format === "date"
            ? t("common.selectDate")
            : format === "month"
            ? t("common.selectMonth")
            : t("common.selectYear")
        }
      />
    </div>
  );
}

export default FilterDate;
