import { Radio } from "antd";
import ReactEcharts from "echarts-for-react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetIncomeOutcomeStatMutation } from "src/app/services/finance";
import { useTypedSelector } from "src/app/store";
import FilterAccount from "src/components/filter/FilterAccount";
import FilterDate from "src/components/filter/FilterDate";
import FilterSource from "src/components/filter/FilterSource";
import { handleMakeStatUrl } from "src/utils";
import ChartHeader from "../../components/chartHeader";
import { monthes } from "../../constants";
import "./profit.scss";
import { useTranslation } from "react-i18next";

function ProfitPage() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);
  //Get section
  const [searchParams] = useSearchParams();
  const [getIncomeOutcomeStat] = useGetIncomeOutcomeStatMutation();
  const { incomeOutcomeStat } = useTypedSelector((state) => state.stat);

  const [filterType, setFilterType] = useState("account");
  const typeId =
    filterType === "account"
      ? searchParams.get("account_id")
      : searchParams.get("source_id");
  const handleGet = (type?: string) => {
    let url = handleMakeStatUrl(searchParams, [
      filterType === "account" ? "account_id" : "source_id",
      "date_year",
      "company_id",
    ]);
    getIncomeOutcomeStat({ params: url, type: type || filterType });
  };

  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeId, searchParams.get("date_year"), searchParams.get("company_id")]);

  //Filter section
  const onFilter = (val: string) => {
    setFilterType(val);
    // handleGet(val);
  };

  const option = {
    title: {},
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      textStyle: {
        color: colors.color_black,
      },
    },
    grid: {
      bottom: "16%",
      left: "3%",
      right: "4%",
      containLabel: true,
      height: 400,
    },
    xAxis: {
      type: "category",
      data: monthes,
    },
    yAxis: {
      type: "value",
      boundaryGap: [0, 0.01],
    },
    series: [
      {
        name: t("common.income"),
        type: "bar",
        stackStrategy: "samesign",
        data: incomeOutcomeStat?.incomeData,
        itemStyle: {
          color: colors.color_green_chart,
        },
      },
      {
        name: t("common.outcome"),
        type: "bar",
        data: incomeOutcomeStat?.outcomeData,
        itemStyle: {
          color: colors.color_red,
        },
      },
    ],
  };

  return (
    <div className="balance" id="profit">
      <div className="balance-content">
        <ChartHeader
          className="profit-chart-header"
          name={t("subControlPanel.income")}
          filters={
            <div className="chart-header-filters">
              {filterType === "source" ? <FilterSource /> : <FilterAccount />}
              <FilterDate format="year" name="date_year" />
              <Radio.Group
                defaultValue="account"
                onChange={(ev) => onFilter(ev.target.value)}
                buttonStyle="solid"
              >
                <Radio.Button value="account">
                  {t("common.accounts")}
                </Radio.Button>
                <Radio.Button value="source">
                  {t("common.sources")}
                </Radio.Button>
              </Radio.Group>
            </div>
          }
        />
        <div className="statistics-chart balance-chart">
          <div className="balance-chart-content" style={{ paddingTop: 24 }}>
            {incomeOutcomeStat && (
              <ReactEcharts
                opts={{ renderer: "svg", height: 586 }}
                option={option}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfitPage;
