import ReactEcharts from "echarts-for-react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetAccountsStatMutation } from "src/app/services/finance";
import { useTypedSelector } from "src/app/store";
import FilterDate from "src/components/filter/FilterDate";
import { handleMakeStatUrl } from "src/utils";
import ChartHeader from "../../components/chartHeader";
import { monthes } from "../../constants";
import "./portion.scss";
import { useTranslation } from "react-i18next";

function PortionPage() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);
  //Get section
  const [searchParams] = useSearchParams();
  const [getAccountsStat] = useGetAccountsStatMutation();
  const { accountsStat } = useTypedSelector((state) => state.stat);

  const handleGet = () => {
    let url = handleMakeStatUrl(searchParams, ["date_year", "company_id"]);
    getAccountsStat(url);
  };
  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("date_year"), searchParams.get("company_id")]);

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // Use axis to trigger tooltip
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    legend: {
      textStyle: {
        color: colors.color_black,
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "16%",
      containLabel: true,
      height: 400,
    },
    xAxis: {
      type: "category",
      data: monthes,
    },
    yAxis: {
      type: "value",
    },
    series: accountsStat?.series,
  };

  return (
    <div className="balance" id="portion">
      <div className="balance-content">
        <ChartHeader
          name={t("common.directions")}
          filters={
            <div className="chart-header-filters">
              <FilterDate format="year" name="date_year" />
            </div>
          }
        />
        <div className="statistics-chart balance-chart">
          <div className="balance-chart-content" style={{ paddingTop: 24 }}>
            <ReactEcharts
              opts={{ renderer: "svg", height: 586 }}
              option={option}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PortionPage;
