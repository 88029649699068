import { Alert, Table, message } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import {
  useDeleteOrganizationMutation,
  useGetOrganizationQuery,
} from "src/app/services/management";
import { IOrganizationTable } from "src/app/services/management/type";
import TableActions from "src/components/view/TableActions";
import ContentTop from "src/layout/content/ContentTop";
import AddCompany from "./components/AddCompany";
import { useGetProfileMutation } from "src/app/services/users";
import { useTranslation } from "react-i18next";

function CompanyPage() {
  const { t } = useTranslation();
  //Get section
  const { data } = useGetOrganizationQuery();
  const [dataSource, setDataSource] = useState<IOrganizationTable[]>([]);

  useEffect(() => {
    if (data) {
      let arr: IOrganizationTable[] = [];
      data.results?.forEach((item) => {
        arr.push({ ...item, key: +item.id });
      });
      setDataSource(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //Delete section
  const [getProfile] = useGetProfileMutation();
  const [deleteOrganization, { isSuccess: deleteStatus }] =
    useDeleteOrganizationMutation();
  useEffect(() => {
    if (deleteStatus) {
      message.success("Item deleted successfully!");
      getProfile();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);

  //Edit section
  const [editableData, setEditableData] = useState<IOrganizationTable>();

  const onEdit = (val: number) => {
    let obj = dataSource?.find((el) => el.id === val);
    if (obj) setEditableData(obj);
  };

  //Table section
  const columns: ColumnsType<IOrganizationTable> = [
    {
      title: t("common.name"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t("common.actions"),
      dataIndex: "key",
      key: "key",
      width: 90,
      fixed: "right",
      render: (key) => (
        <TableActions onEdit={() => onEdit(key)} deleteVisible={false} />
      ),
    },
  ];

  return (
    <div className=" account">
      <ContentTop title="Kompaniyalar">
        <AddCompany
          addVisible={!!data && data.results.length === 0}
          editableData={editableData}
          setEditableData={setEditableData}
        />
      </ContentTop>

      {data && data.results.length === 0 && (
        <Alert
          message={t("company.alert")}
          type="warning"
          showIcon
          closable
          style={{ marginBottom: 24 }}
        />
      )}

      <Table
        columns={columns}
        dataSource={dataSource}
        className="account-table"
        pagination={false}
      />
    </div>
  );
}

export default CompanyPage;
