import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IBranchRes } from "../services/management/type";

export interface IBranchState {
  branchs: IBranchRes[];
}

const initialState: IBranchState = {
  branchs: [],
};

const BranchSlice = createSlice({
  name: "branches",
  initialState,
  reducers: {
    saveBranches: (state, action: PayloadAction<IBranchState["branchs"]>) => {
      state.branchs = action.payload;
    },
  },
  //   extraReducers: (builder) => {
  //     builder.addMatcher(
  //       managementApi.endpoints.addBranches.matchFulfilled,
  //       (state, action) => {
  //         state.branchs = [state.branchs, action.payload];
  //       }
  //     );
  //   },
});

export const { saveBranches } = BranchSlice.actions;

export default BranchSlice.reducer;
