import { configureStore, ConfigureStoreOptions } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { api } from "./services/api";
import { rtkQueryErrorLogger } from "./services/api/middlewares";
import users from "src/app/slices/usersSlice";
import project from "src/app/slices/projectSlice";
import layout from "src/app/slices/layoutSlice";
import account from "src/app/slices/accountSlice";
import source from "src/app/slices/sourceSlice";
import journal from "src/app/slices/journalSlice";
import stat from "src/app/slices/statSlice";
import contingent from "src/app/slices/contingentSlice";
import monitor from "src/app/slices/monitorSlice";
import transaction from "src/app/slices/transactionSlice";
import direction from "src/app/slices/directionSlice";
import currency from "src/app/slices/currencySlice";
import commitment from "src/app/slices/commitmentSlice";
import akt from "src/app/slices/aktSlice";
import pnl from "src/app/slices/pnlSlice";
import branch from "src/app/slices/branches";

export const createStore = (
  options?: ConfigureStoreOptions["preloadedState"] | undefined
) =>
  configureStore({
    reducer: {
      [api.reducerPath]: api.reducer,
      users,
      project,
      layout,
      account,
      source,
      journal,
      stat,
      contingent,
      monitor,
      transaction,
      direction,
      currency,
      commitment,
      akt,
      pnl,
      branch,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware, rtkQueryErrorLogger),
    ...options,
  });

export const store = createStore();

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export const getRootState = () => store.getState();
