import FilterDate from "src/components/filter/FilterDate";
import ContentTop from "src/layout/content/ContentTop";
import DebtCreditTable from "./components/DebtCreditTable";
import "./debt_credit.scss";
import useDebitCredit from "./useDebitCredit";
import { useTranslation } from "react-i18next";

function DebtCreditPage() {
  const { t } = useTranslation();
  const { isLoading, data } = useDebitCredit();

  return (
    <div className="debt_credit">
      <ContentTop>
        <FilterDate format="year" />
      </ContentTop>

      <div className="debt_credit_content">
        <DebtCreditTable
          title={t("subHelper.bookWithCustomers")}
          titleColumn={t("subHelper.madeService")}
          titleColumn2={t("subHelper.payedCustomer")}
          isLoading={isLoading}
          data={data?.customer}
          type="customer"
        />
        <DebtCreditTable
          title={t("subHelper.bookWithDillers")}
          titleColumn={t("subHelper.buyed")}
          titleColumn2={t("subHelper.payed")}
          isLoading={isLoading}
          data={data?.supplier}
          type="supplier"
        />
        {/* <DebtCreditTable
          title="Orendatorlar bilan hisob-kitoblar"
          titleColumn="Hisoblandi"
          titleColumn2="To'landi"
          isLoading={isLoading}
          data={data?.rent}
          type="rent"
        /> */}
        <DebtCreditTable
          title={t("subHelper.bookWithEmployes")}
          titleColumn={t("subHelper.calculated")}
          titleColumn2={t("subHelper.payed")}
          isLoading={isLoading}
          data={data?.employee}
          type="employee"
        />
      </div>
    </div>
  );
}

export default DebtCreditPage;
