import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useConfirmJournalChangeMutation,
  useDeleteJournalChangeMutation,
  useGetJournalChangeMutation,
} from "src/app/services/finance";
import { useTypedSelector } from "src/app/store";
import FilterDate from "src/components/filter/FilterDate";
import FilterAccount from "src/components/filter/FilterAccount";
import ContentTop from "src/layout/content/ContentTop";
import { handleMakeUrl } from "src/utils";
import EditedCard from "./components/EditedCard";
import "./edited.scss";
import { message } from "antd";
import PaginationFilter from "src/components/filter/PaginationFilter";
import { useTranslation } from "react-i18next";

function Edited() {
  //Get section
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [getSource, { data }] = useGetJournalChangeMutation();
  const { journalChanges } = useTypedSelector((state) => state.journal);
  const handleGet = () => {
    let url = handleMakeUrl(searchParams, { is_accepted: false });
    getSource(url);
  };
  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  //Confirm section
  const [confirmJournalChange, { isSuccess: confirmStatus }] =
    useConfirmJournalChangeMutation();
  const onConfirm = (val: number) => {
    confirmJournalChange(val);
  };
  useEffect(() => {
    if (confirmStatus) {
      handleGet();
      message.success("Tasdiqlash muvaffaqiyatli yakunlandi!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmStatus]);

  //Confirm section
  const [deleteJournalChange, { isSuccess: deleteStatus }] =
    useDeleteJournalChangeMutation();
  const onDelete = (val: number) => {
    deleteJournalChange(val);
  };
  useEffect(() => {
    if (deleteStatus) {
      handleGet();
      message.success("O'chirish muvaffaqiyatli yakunlandi!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);

  return (
    <div className="edited">
      <ContentTop>
        <FilterAccount />
        <FilterDate />
      </ContentTop>

      <h2>{t("sidebar.check.menu1")}</h2>
      <div className="edited-cards">
        {journalChanges?.map((item) => {
          return (
            <EditedCard
              id={item.id}
              key={item.id}
              data={item}
              onConfirm={onConfirm}
              onDelete={onDelete}
            />
          );
        })}
      </div>
      {data && <PaginationFilter total={data?.count} />}
    </div>
  );
}

export default Edited;
