import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IContingent } from "src/app/services/management/type";
import { DebtCreditAttributes } from "src/app/services/type";
import { IDebtCreditTableObj, makeDebtCreditColumns } from "./columns";
import { useTypedSelector } from "src/app/store";
const { innerWidth } = window;

interface Props {
  title: string;
  titleColumn: string;
  titleColumn2: string;
  isLoading?: boolean;
  data?: IDebtCreditTableObj[];
  type?: DebtCreditAttributes;
}

interface IPerson extends IContingent {
  full_name: string;
}

export default function DebtCreditTable({ title, titleColumn, titleColumn2, isLoading, data = [], type }: Props) {
  const { t } = useTranslation();
  const [list, setList] = useState<IDebtCreditTableObj[]>([]);
  const { colors } = useTypedSelector((state) => state.layout);

  const Title = () => (
    <div className="debt_credit-table-title">
      {title}{" "}
      <span>
        {t("common.entitlement")} (+); {t("common.deposit")} (-)
      </span>
    </div>
  );

  const titleItem = {
    title: <Title />,
    dataIndex: "contingent",
    key: "contingent",
    width: 220,
    render: (val: IPerson) => val?.first_name,
  };

  if (innerWidth > 700) Object.assign(titleItem, { fixed: "left" });

  const columns: ColumnsType<IDebtCreditTableObj> = [
    titleItem,
    ...makeDebtCreditColumns({ title: titleColumn, title2: titleColumn2, t }),
  ];

  const makeAllPlus = (key: keyof IDebtCreditTableObj) => {
    let a = 0;
    data?.map((item) => {
      if (item?.[key]) a = a + Number(item?.[key]);
    });
    return a;
  };

  const giveData = () => {
    setList([
      {
        contingent: { id: 0, first_name: "Umumiy" },
        apr_credit: makeAllPlus("apr_credit"),
        apr_debt: makeAllPlus("apr_debt"),
        apr_gap: makeAllPlus("apr_gap"),
        aug_credit: makeAllPlus("aug_credit"),
        aug_debt: makeAllPlus("aug_debt"),
        aug_gap: makeAllPlus("aug_gap"),
        dec_credit: makeAllPlus("dec_credit"),
        dec_debt: makeAllPlus("dec_debt"),
        dec_gap: makeAllPlus("dec_gap"),
        feb_credit: makeAllPlus("feb_credit"),
        feb_debt: makeAllPlus("feb_debt"),
        feb_gap: makeAllPlus("feb_gap"),
        jan_credit: makeAllPlus("jan_credit"),
        jan_debt: makeAllPlus("jan_debt"),
        jan_gap: makeAllPlus("jan_gap"),
        july_credit: makeAllPlus("july_credit"),
        july_debt: makeAllPlus("july_debt"),
        july_gap: makeAllPlus("july_gap"),
        june_credit: makeAllPlus("june_credit"),
        june_debt: makeAllPlus("june_debt"),
        june_gap: makeAllPlus("june_gap"),
        mar_credit: makeAllPlus("mar_credit"),
        mar_debt: makeAllPlus("mar_debt"),
        mar_gap: makeAllPlus("mar_gap"),
        may_credit: makeAllPlus("may_credit"),
        may_debt: makeAllPlus("may_debt"),
        may_gap: makeAllPlus("may_gap"),
        nov_credit: makeAllPlus("nov_credit"),
        nov_debt: makeAllPlus("nov_debt"),
        nov_gap: makeAllPlus("nov_gap"),
        oct_credit: makeAllPlus("oct_credit"),
        oct_debt: makeAllPlus("oct_debt"),
        oct_gap: makeAllPlus("oct_gap"),
        sep_credit: makeAllPlus("sep_credit"),
        sep_debt: makeAllPlus("sep_debt"),
        sep_gap: makeAllPlus("sep_gap"),
      },
      ...data,
    ]);

    updateNegativeCellColor();
  };

  const updateNegativeCellColor = async () => {
    var intervalId = setInterval(updateColor, 100);

    function updateColor() {
      document.querySelectorAll("td").forEach(function (td) {
        let prefix = td?.textContent?.[0];
        if (prefix === "-") {
          td.style.color = colors.color_red;
        } else if (td?.textContent === "0") {
          // td.style.color = colors.light_gray;
        }
      });
    }

    setTimeout(function () {
      clearInterval(intervalId);
    }, 400);
  };

  useEffect(() => giveData(), [data]);

  return (
    <div className="debt_credit-table">
      <Table
        scroll={{ x: 1200 }}
        bordered={true}
        columns={columns}
        dataSource={list}
        pagination={false}
        loading={isLoading}
        className={"debt_credit-table-" + type}
      />
    </div>
  );
}
