import { Outlet, useLocation } from "react-router-dom";
import { changeCollapsed } from "src/app/slices/layoutSlice";
import { useAppDispatch, useTypedSelector } from "src/app/store";
import { isSidebarFixed } from "src/static/constants";
import Header from "./header";
import Sidebar from "./sidebar";
import "./style.scss";
import { findMobileFull, noPaddingPathes } from "./util";
import { Spin } from "antd";

function DashboardLayout() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { profile } = useTypedSelector((state) => state.users);
  const { collapsed } = useTypedSelector((state) => state.layout);

  const handleClick = () => {
    if (isSidebarFixed && !collapsed) dispatch(changeCollapsed());
  };

  return (
    <Spin spinning={profile?.id ? false : true} tip="Yuklanmoqda">
      <div className="dashboard" onClick={handleClick}>
        <Sidebar />
        <div
          className={`dashboard-right ${
            collapsed ? "dashboard-collapsed" : ""
          }`}
        >
          <Header />
          <div
            className={`dashboard-content ${findMobileFull(
              location.pathname.split("/")[2]
            )} ${
              noPaddingPathes.includes(location.pathname)
                ? "dashboard-content-nopadding"
                : ""
            }`}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default DashboardLayout;
