import { Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useAddBranchesMutation,
  useEditOrganizationMutation,
  useGetBranchesMutation,
} from "src/app/services/management";
import { IBranch, IBranchRes } from "src/app/services/management/type";
import { useGetProfileMutation } from "src/app/services/users";
import { saveBranches } from "src/app/slices/branches";
import { useAppDispatch } from "src/app/store";
import { PlusIcon } from "src/assets/svg";
import CustomButton from "src/components/common/button";
import CustomModal from "src/components/common/modal";
import ModalFooter from "src/components/common/modal/ModalFooter";
import ModalHeader from "src/components/common/modal/ModalHeader";

interface Props {
  addVisible: boolean;
  editableData?: IBranchRes;
  setEditableData: React.Dispatch<React.SetStateAction<IBranchRes | undefined>>;
}

function AddBranch({ addVisible, editableData, setEditableData }: Props) {
  const dispatch = useAppDispatch();
  const [getBranches] = useGetBranchesMutation();
  const [getProfile] = useGetProfileMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditableData(undefined);
    form.resetFields();
  };

  //Form section
  const [addBranches, { isSuccess: addStatus }] = useAddBranchesMutation();
  const [editOrganization, { isSuccess: editStatus }] =
    useEditOrganizationMutation();
  const [form] = Form.useForm();
  const onFinish = (val: IBranch) => {
    if (editableData) editOrganization({ id: editableData.id, body: val });
    else
      addBranches({ ...val, is_branch: true }).then(() => {
        getBranches("")
          .unwrap()
          .then((val) => {
            let arr: IBranchRes[] = [];
            val.results?.forEach((item) => {
              arr.push({ ...item, key: item.id });
            });
            dispatch(saveBranches(arr));
          })
          .then(() => getProfile());
      });
  };

  useEffect(() => {
    if (addStatus) {
      message.success("Organization added successfully!");
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);

  useEffect(() => {
    if (editStatus) {
      message.success(`${editableData?.title} edited successfully!`);
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editStatus]);

  //Set data for edit
  const handleSet = () => {
    if (editableData) {
      showModal();
      form.resetFields();
      let { title } = editableData;
      form.setFieldsValue({
        title,
      });
    }
  };

  useEffect(() => {
    handleSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableData]);

  return (
    <div className="add">
      {addVisible && (
        <CustomButton
          className="content-top-btn"
          height={38}
          onClick={showModal}
        >
          <div className="content-top-btn-content">
            <PlusIcon /> {t("common.add")}
          </div>
        </CustomButton>
      )}

      <CustomModal
        open={isModalOpen}
        width={640}
        onCancel={closeModal}
        centered={true}
      >
        <ModalHeader
          title={`${
            editableData
              ? editableData.title + " " + t("subHelper.editInformation")
              : t("branches.addBranch")
          }`}
        />

        <Form
          form={form}
          name="normal_login"
          className="form-form"
          onFinish={onFinish}
          layout={"vertical"}
          requiredMark={false}
        >
          <Form.Item
            name="title"
            label={t("branches.nameBranch") + "*"}
            rules={[{ required: true, message: t("branches.enterBranch") }]}
            className="full"
          >
            <Input />
          </Form.Item>
          <ModalFooter onCancel={closeModal} />
        </Form>
      </CustomModal>
    </div>
  );
}

export default AddBranch;
