import { Alert, Table, message } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import {
  useDeleteOrganizationMutation,
  useGetBranchesMutation,
  useSelectBranchesMutation,
} from "src/app/services/management";
import { IBranchRes } from "src/app/services/management/type";
import ContentTop from "src/layout/content/ContentTop";
import { useGetProfileMutation } from "src/app/services/users";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useTypedSelector } from "src/app/store";
import { saveBranches } from "src/app/slices/branches";
import AddBranch from "./components/AddBranch";
import TableActions from "src/components/view/TableActions";

function BranchesPage() {
  const profile = useTypedSelector((state) => state.users.profile);
  const branches = useTypedSelector((state) => state.branch.branchs);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  //Get section
  const [getBranches, { data, isLoading }] = useGetBranchesMutation();
  const [selectBranches, { isLoading: selectBranchLoading }] =
    useSelectBranchesMutation();

  useEffect(() => {
    if (branches.length == 0) {
      getBranches("")
        .unwrap()
        .then((val) => {
          let arr: IBranchRes[] = [];
          val.results?.forEach((item) => {
            arr.push({ ...item, key: item.id });
          });
          dispatch(saveBranches(arr));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Delete section
  const [getProfile, { isLoading: profileLoading }] = useGetProfileMutation();
  const [deleteOrganization, { isSuccess: deleteStatus }] =
    useDeleteOrganizationMutation();
  useEffect(() => {
    if (deleteStatus) {
      message.success("Item deleted successfully!");
      getProfile();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);

  //Edit section
  const [editableData, setEditableData] = useState<IBranchRes>();

  const onEdit = (val: number) => {
    let obj = branches?.find((el) => el.id === val);
    if (obj) setEditableData(obj);
  };

  //Table section
  const columns: ColumnsType<IBranchRes> = [
    {
      title: t("common.name"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t("common.actions"),
      dataIndex: "key",
      key: "key",
      width: 90,
      fixed: "right",
      render: (key) => (
        <TableActions
          onEdit={() => onEdit(key)}
          onDelete={() => onEdit(key)}
          disabled
        />
      ),
    },
    // {
    //   title: t("common.actions"),
    //   dataIndex: "key",
    //   key: "key",
    //   width: 90,
    //   fixed: "right",
    //   render: (_, render) =>
    //     render.id == profile?.company.id ? (
    //       <Switch value={true} disabled />
    //     ) : (
    //       <Switch
    //         value={false}
    //         onChange={() =>
    //           selectBranches({ company: render.id })
    //             .then(() => getBranches(""))
    //             .then(() => getProfile())
    //         }
    //       />
    //     ),
    // },
  ];

  return (
    <div className="account">
      <ContentTop title="Filiallar">
        <AddBranch
          addVisible={true}
          editableData={editableData}
          setEditableData={setEditableData}
        />
      </ContentTop>

      {data && data.results.length === 0 && (
        <Alert
          message={t("company.alert")}
          type="warning"
          showIcon
          closable
          style={{ marginBottom: 24 }}
        />
      )}

      <Table
        loading={isLoading || selectBranchLoading || profileLoading}
        columns={columns}
        dataSource={branches.filter((item) => item?.is_branch)}
        className="account-table"
        pagination={false}
      />
    </div>
  );
}

export default BranchesPage;
