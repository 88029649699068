import { Anchor, Flex } from "antd";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import FilterDate from "src/components/filter/FilterDate";
import { pnlStatMenu } from "../constants";
import "../control_panel.scss";
import BasePnlStat from "./base";
import ProfitAndSoftStat from "./profit_and_soft";
import { useTranslation } from "react-i18next";
import useParamsHook from "src/hooks/params";
import { FilterCompanyAll } from "src/components/filter/FilterCompanyAll";

function PnlStats() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { handleMakeParams } = useParamsHook();

  useEffect(() => {
    if (!searchParams.get("date")) setSearchParams({ date: "2024" });
  }, []);
  return (
    <div>
      <div className="control_panel">
        <div className="control_panel-menu">
          <Anchor items={pnlStatMenu(t)} direction="horizontal" />

          <Flex align="center" gap={24}>
            <FilterCompanyAll />

            <FilterDate format="year" />
          </Flex>
        </div>
        <div className="control_panel-content">
          <ProfitAndSoftStat />
          <BasePnlStat type="overhead" />
          <BasePnlStat type="gross" />
          <BasePnlStat type="operative" />
          <BasePnlStat type="soft" />
        </div>
      </div>
    </div>
  );
}

export default PnlStats;
