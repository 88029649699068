import { Radio } from "antd";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import FilterDate from "src/components/filter/FilterDate";
import {
  formattedLastMonth,
  formattedThisMonth,
  isSidebarFixed,
} from "src/static/constants";
import "../dashboard.scss";
import { useTranslation } from "react-i18next";
import { FilterCompanyAll } from "src/components/filter/FilterCompanyAll";

const options = [
  { label: "Bugun", value: "today" },
  { label: "Kecha", value: "yesterday" },
  { label: "Kunlik", value: "day" },
  { label: "Haftalik", value: "week" },
  { label: "Oylik", value: "month" },
  // { label: "Hammasi", value: "all" },
];

function Filter() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const options2 = [
    { label: t("common.thisMonth"), value: "this" },
    { label: t("common.lastMonth"), value: "last" },
  ];

  //Default constans
  const type = searchParams.get("type");
  const date = searchParams.get("date");

  const handleMakeParams = (key: string, value: string) => {
    if (value) {
      if (searchParams.has(key)) searchParams.set(key, value);
      else searchParams.append(key, value);
    } else searchParams.delete(key);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    // if (!type) handleMakeParams("type", "today");
    // if (!date) handleMakeParams("date", formattedToday);
    if (!type) handleMakeParams("type", "this");
    if (!date) handleMakeParams("date", formattedThisMonth);
  }, []);

  //Change functions

  const onTypeChange = (val: string) => {
    handleMakeParams("type", val);
    if (val === "this") {
      handleMakeParams("date", formattedThisMonth);
    } else if (val === "last") {
      handleMakeParams("date", formattedLastMonth);
    }
  };

  return (
    <div className="dashboardPage-filter-wrap">
      <Radio.Group
        options={options2}
        onChange={(ev) => onTypeChange(ev.target.value)}
        value={type ?? "this"}
        size="large"
        optionType="button"
        className="dashboardPage-filter"
      />
      <FilterDate format="month" page="dashboard" large={!isSidebarFixed} />

      <FilterCompanyAll className="customCheckbox__transparent" />
    </div>
  );
}

export default Filter;
