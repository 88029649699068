import { Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import { position } from "src/app/services/users/const";
import { useTypedSelector } from "src/app/store";
import useParamsHook from "src/hooks/params";

export const FilterCompanyAll = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const { handleMakeParams, searchParams } = useParamsHook();
  const profile = useTypedSelector((state) => state.users.profile);
  const userPostion = profile?.position || position;

  return (
    <>
      {!profile?.parent_company?.is_branch &&
        !["cfo", "operator", "accountant"].includes(String(userPostion)) && (
          <Checkbox
            checked={searchParams.get("company_id") ? true : false}
            className={`customCheckbox ${className}`}
            onChange={(val) =>
              handleMakeParams("company_id", val.target.checked ? "all" : "")
            }
          >
            {t("common.allBranchsStat")}
          </Checkbox>
        )}
    </>
  );
};
